import { useFormik } from "formik";
import React, { useLayoutEffect, useState } from "react";
import { quizSearch } from "../../../schema";
import { Link } from "react-router-dom";
import AdminListService from "../../../services/admin-list.service";
import AdminFooter from "../includes/AdminFooter";
import HeaderSidebar from "../includes/HeaderSidebar";
import MoveTable from "../includes/MoveTable";
import ToTop from "../includes/ToTop";
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import moment from "moment";
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from "react-toastify";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Helmet } from "react-helmet";

export default function Year() {
  const TITLE = "Ashley Sleep Elite | 1099";
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState("10");
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { height, width } = useWindowDimensions();
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const getResultData = async (e) => {
    setLoading(true);

    try {
      if (values.fieldtype === "" && values.searchval === "") {
        const response = await AdminListService.getsearch1099ClaimsList(
          "",
          e,
          limit,
          1
        );
        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
          setLoading(false);
        }
      } else if (values.fieldtype === "" || values.searchval === "") {
        setLoading(false);

        handleSubmit();
      } else {
        let resultData;
      }
    } catch (err) {
      setResultData([]);
      setTotalResults("0");
      setTotalPages("1");
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const changeLimit = (e) => {
    let newLimit = e;
    const getResultData = async () => {
      try {
        if (values.fieldtype === "" && values.searchval === "") {
          setLoading(true);

          setLimit(newLimit);

          const response = await AdminListService.getsearch1099ClaimsList(
            "",
            "",
            newLimit,
            1
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setTotalResults(response.data.records);
            setCurrentPage(1);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" && values.searchval !== "") {
          setLoading(true);

          setLimit(newLimit);

          const response = await AdminListService.getsearch1099ClaimsList(
            values.fieldtype,
            values.searchval,
            newLimit,
            1
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;

            setResultData(resultData);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" || values.searchval !== "") {
          handleSubmit();
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const changePagination = (e) => {
    setCurrentPage(e);

    // console.log(e)
    let pageNo = e;
    // setLimit(newLimit);
    console.log(limit);
    const getResultData = async () => {
      setLoading(true);

      try {
        const response = await AdminListService.getsearch1099ClaimsList(
          values.fieldtype,
          values.searchval,
          limit,
          pageNo
        );

        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;

          setResultData(resultData);
          setLimit(response.data.per_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const searchData = async (action) => {
    setLoading(true);
    try {
      const response = await AdminListService.getsearch1099ClaimsList(
        values.fieldtype,
        values.searchval,
        limit,
        1
      );
      let resultData;
      if (response.status === 206) {
        setLoading(false);
        resultData = response.data.response;
        setResultData(resultData);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setLimit(response.data.per_page);
      } else {
        setTotalResults("0");
        setTotalPages("1");
        setResultData([]);
        setLoading(false);
      }
    } catch (err) {
      setTotalPages("1");
      setTotalResults("0");
      if (err.response.status === 500) {
        setLoading(false);
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };


  useLayoutEffect(() => {
    const getResultData = async () => {
      setLoading(true);
      try {
        const response = await AdminListService.Get1099ClaimsList();
        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setLimit(response.data.per_page);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLoading(false);
        }
      } catch (err) {
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  }, []);

  // const Table = ({ data, rowsPerPage }) => {
  //   const [page, setPage] = useState(1);
  //   const { slice, range } = useTable(data, page, rowsPerPage);
  //   const { items, requestSort, sortConfig } = useSortableData(slice);

  //   const getClassNamesFor = (name) => {
  //     if (!sortConfig) {
  //       return;
  //     }
  //     return sortConfig.key === name ? sortConfig.direction : undefined;
  //   };

  //   const allSelect = async () => {
  //     $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
  //   };
  //   return (
  //     <>
  //       <table className="table caption-top align-middle table-borderless table-style-1">
  //         <thead>
  //           <tr>
  //             {/* <th
  //               scope="col"
  //               onClick={() => requestSort("Select")}
  //               className={getClassNamesFor("Select")}
  //             >
  //               <label htmlFor="Select">
  //                 <input
  //                   id="Select"
  //                   className="form-check-input slect_all"
  //                   type="checkbox"
  //                   onChange={allSelect}
  //                 />{" "}
  //                 Select
  //               </label>
  //             </th> */}
  //             <th
  //               scope="col"
  //               onClick={() => requestSort("id")}
  //               className={getClassNamesFor("id")}
  //             >
  //               No
  //             </th>
  //             <th
  //               style={{ textAlign: "center" }}
  //               scope="col"
  //               onClick={() => requestSort("user_info")}
  //               className={getClassNamesFor("user_info")}
  //             >
  //               User Info
  //             </th>
  //             <th
  //               style={{ textAlign: "center" }}
  //               scope="col"
  //               onClick={() => requestSort("id")}
  //               className={getClassNamesFor("id")}
  //             >
  //               Denomination
  //             </th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {items.length ? (
  //             items.map((el, index) => (
  //               <tr key={el.id}>
  //                 {/* <td>
  //                   <input
  //                     className="form-check-input select_one"
  //                     id={el.id}
  //                     value={el.id}
  //                     type="checkbox"
  //                   />
  //                 </td> */}
  //                 <td>
  //                   {currentPage === 1
  //                     ? index + 1
  //                     : (currentPage - 1) * limit + index + 1}
  //                 </td>

  //                 <td>
  //                   <div className="row">
  //                     <div className="col-xl-6">
  //                       <div className="claim-details-tab">
  //                         {/* <h5 className="claim-detail-heading">User Info</h5> */}
  //                         <ul className="rob-rollmann">
  //                           <li>
  //                             <b>UDF: </b>
  //                             {el.emp_number}
  //                           </li>

  //                           <li>
  //                             <b>First Name: </b>
  //                             {el.first_name}
  //                           </li>
  //                           <li>
  //                             <b>Last Name: </b>
  //                             {el.last_name}
  //                           </li>
  //                           <li>
  //                             <b>Email: </b>
  //                             {el.email}
  //                           </li>
  //                         </ul>
  //                       </div>
  //                     </div>

  //                     <div className="col-xl-6">
  //                       <div className="claim-details-tab">
  //                         {/* <h5 className="claim-detail-heading">User Info</h5> */}
  //                         <ul className="rob-rollmann">
  //                           <li>
  //                             <b>Address: </b>
  //                             {el.address1}
  //                           </li>
  //                           <li>
  //                             <b>City: </b>
  //                             {el.city > ""
  //                               ? el.city
  //                               : "N/A"}
  //                           </li>
  //                           <li>
  //                             <b>State: </b>
  //                             {el.state > ""
  //                               ? el.state
  //                               : "N/A"}
  //                           </li>

  //                           <li>
  //                             <b>Zip: </b>
  //                             {el.zip}
  //                           </li>
  //                         </ul>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </td>
  //                 <td>$ {el.amount}</td>
  //               </tr>
  //             ))
  //           ) : (
  //             <tr>
  //               <td colSpan="6" className="text-center text-capitalize">
  //                 No record found
  //               </td>
  //             </tr>
  //           )}
  //         </tbody>
  //       </table>
  //     </>
  //   );
  // };
  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const allSelect = async () => {
      $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
    };
    return (
      <>
        <table className=" caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              {/* <th
                scope="col"
                onClick={() => requestSort("Select")}
                className={getClassNamesFor("Select")}
              >
                <label htmlFor="Select">
                  <input
                    id="Select"
                    className="form-check-input slect_all"
                    type="checkbox"
                    onChange={allSelect}
                  />{" "}
                  Select
                </label>
              </th> */}
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                Denomination
              </th>
              <th
                scope="col"
                onClick={() => requestSort("email")}
                className={getClassNamesFor("email")}
              >
                Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("last_name")}
                className={getClassNamesFor("last_name")}
              >
                Last Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Address
              </th>
              <th
                scope="col"
                onClick={() => requestSort("city")}
                className={getClassNamesFor("city")}
              >
                City
              </th>
              <th
                scope="col"
                onClick={() => requestSort("state")}
                className={getClassNamesFor("state")}
              >
                State
              </th>
              <th
                scope="col"
                onClick={() => requestSort("zip")}
                className={getClassNamesFor("zip")}
              >
                Zip
              </th>
              <th
                scope="col"
                onClick={() => requestSort("emp_number")}
                className={getClassNamesFor("emp_number")}
              >
                UDF
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={el.id}>
                  {/* <td>
                    <input
                      className="form-check-input select_one"
                      id={el.id}
                      value={el.id}
                      type="checkbox"
                    />
                  </td> */}
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>$ {el.amount}</td>
                  <td>{el.email}</td>
                  <td>{el.first_name}</td>
                  <td>{el.last_name}</td>
                  <td>{el.address1}</td>
                  <td>{el.city}</td>
                  <td>{el.state}</td>
                  <td>{el.zip}</td>
                  <td>{el.emp_number}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        console.log("searchData");
        searchData(action);
      },
    });

  setTimeout(() => {
    setLoading(false);
  }, 1000);
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="semi-dark">
        <div className="wrapper">
          <HeaderSidebar />
          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>
                    1099 <span>[{totalResults}]</span>
                  </h2>
                </div>

                <div className="slides-here">
                  <div className="alert alert-info">
                    Info! You can search your required data by putting text in
                    search box
                  </div>
                  <div className="main-content-box">
                    <div className="manage-territories-box">
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                          <div className="col-lg-12">
                            <h2 className="manage-territories-heading">1099</h2>
                          </div>

                          <div className="col-lg-3">
                            <div className="form-group custom-group mt-4">
                              <select
                                className={`form-select ${
                                  errors.fieldtype && touched.fieldtype
                                    ? "is-danger"
                                    : ""
                                }`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="fieldtype"
                                value={values.fieldtype || ""}
                                required
                              >
                                <option value="">Search By</option>
                                {/* <option value="1">Denomination</option> */}
                                <option value="2">Email</option>
                                <option value="3">First Name</option>
                                <option value="4">Last Name</option>
                                <option value="5">Address</option>
                                <option value="6">City</option>
                                <option value="7">State</option>
                                <option value="8">Zip</option>
                                <option value="9">UDF</option>
                              </select>
                              {errors.fieldtype && touched.fieldtype ? (
                                <p className="help is-danger">
                                  {errors.fieldtype}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.searchval && touched.searchval
                                    ? "is-danger"
                                    : ""
                                }`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="searchval"
                                placeholder="Enter something to search"
                                value={values.searchval || ""}
                                required
                              />{" "}
                              <button
                                className="input-group-text back-orange"
                                id="basic-addon2"
                              >
                                Search
                              </button>
                            </div>
                            {errors.searchval && touched.searchval ? (
                              <p className="help is-danger">
                                {errors.searchval}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-2">
                            <div className="form-group custom-group">
                              <label className="form-label"></label>
                              <select
                                className="form-select"
                                value={limit}
                                onChange={(e) => changeLimit(e.target.value)}
                              >
                                <option>10</option>
                                <option>50</option>
                                <option>100</option>
                                <option>200</option>
                                <option>300</option>
                                <option>400</option>
                                <option>500</option>
                                <option>600</option>
                                <option>700</option>
                                <option>800</option>
                                <option>900</option>
                                <option>1000</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <button
                              className="btn btn-primary back-orange yearReset"
                              type="reset"
                              onClick={() => window.location.reload(false)}
                            >
                              Reset
                            </button>
                          </div>

                          {/* <div className="col-lg-3">
                            <label>Starting Date</label>
                            <div className="reacter-datepicker">
                              <input
                                type="date"
                                name="start"
                                className="form-control"
                                onChange={(e) => {
                                }}
                                placeholder="mm-dd-yyyy"
                              />
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <label>Ending Date</label>
                            <div className="reacter-datepicker">
                              <input
                                type="date"
                                name="end"
                                className="form-control"
                                placeholder="mm-dd-yyyy"
                              />
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <div className="input-group custom-group mt-4">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.searchvall && touched.searchvall
                                    ? "is-danger"
                                    : ""
                                }`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="searchval"
                                placeholder="Enter UDF"
                                value={values.searchvall || ""}
                                required
                              />{" "}
                              <button
                                className="input-group-text back-orange"
                                id="basic-addon2"
                              >
                                Search
                              </button>
                              {errors.searchvall && touched.searchvall ? (
                                <p className="help is-danger">
                                  {errors.searchvall}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-3 mt-1">
                            <a className="btn btn-primary back-orange export-rang yearRecord">
                              Export by range
                            </a>
                          </div> */}
                        </div>
                      </form>
                    </div>
                    {/* <MoveTable /> */}
                    <div className="claim-table">
                      <Table data={resultData} rowsPerPage={limit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="pagi">
                  {totalResults > limit && totalPages > 1 ? (
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={parseInt(limit)}
                      totalItemsCount={totalResults}
                      onChange={(e) => {
                        changePagination(e);
                      }}
                      pageRangeDisplayed={
                        width >= 1024
                          ? 8
                          : width >= 768
                          ? 6
                          : width >= 425
                          ? 3
                          : width >= 375
                          ? 2
                          : 1
                      }
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First Page"
                      lastPageText="Last Page"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </main>
          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
