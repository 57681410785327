import React from "react";
import { Link } from "react-router-dom";
import AdminFooter from "../../../Components/Admin/includes/AdminFooter";
import ToTop from "../../../Components/Admin/includes/ToTop";
import { Helmet } from "react-helmet";
import HeaderSidebar1099 from "../includes/HeaderSidebar1099";

export default function Dashboard1099() {
  const TITLE = "Ashley Sleep Elite | Dashboard";
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="semi-dark">
        <div className="wrapper">
          <HeaderSidebar1099 />

          <main className="page-content">
            <div className="manage-heading-2">
              <h2>
                Welcome, <span>1099 Admin</span>
              </h2>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xxl-3 gy-3">
              <div className="col">
                <div className="card radius-10">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <i
                          class="bi bi-people-fill"
                          style={{ color: "#00a9e0", fontSize: "30px" }}
                        ></i>
                        <h4 className="mb-0 text-orange">RSA's Balance</h4>
                      </div>
                      <div className="ms-auto fs-2 text-orange text-center">
                        <Link
                          to="/1099/balance/rsa"
                          className="btn btn-primary px-4 d-block back-blue"
                        >
                          More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <i
                          class="bi bi-people-fill"
                          style={{ color: "#00a9e0", fontSize: "30px" }}
                        ></i>
                        <h4 className="mb-0 text-orange">Manager Balance</h4>
                      </div>
                      <div className="ms-auto fs-2 text-orange text-center">
                        <Link
                          to="/1099/balance/manager"
                          className="btn btn-primary px-4 d-block back-blue"
                        >
                          More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <ToTop />
          {/* <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div> */}
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
